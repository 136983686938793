import React from "react";

import PropTypes from "prop-types";

import {
  HeroSection,
  HeroContent,
  HeroImage,
  HeroLeft,
  HeroTitle,
  HeroSubtitle,
} from "./styles";

const Hero = ({
  title,
  subtitle,
  imgSrc,
  img,
  reverseImg,
  divider,
  bgBlueGreen,
  titleSmall,
  children,
}) => (
  <HeroSection bgBlueGreen={bgBlueGreen}>
    <HeroContent>
      {reverseImg
        ? null
        : img || (!!imgSrc && <HeroImage src={imgSrc} loading="lazy" />)}
      <HeroLeft small={titleSmall}>
        {divider}
        {title && <HeroTitle small={titleSmall}>{title}</HeroTitle>}
        {subtitle && <HeroSubtitle>{subtitle}</HeroSubtitle>}
        {children && (
          <div className="hero-description" style={{ marginBottom: "3em" }}>
            {children}
          </div>
        )}
      </HeroLeft>
      {reverseImg
        ? img || (!!imgSrc && <HeroImage src={imgSrc} loading="lazy" />)
        : null}
    </HeroContent>
  </HeroSection>
);

Hero.defaultProps = {
  title: null,
  subtitle: null,
  imgSrc: null,
  img: null,
  reverseImg: false,
  divider: null,
  bgBlueGreen: false,
  titleSmall: false,
  children: null,
};

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  imgSrc: PropTypes.string,
  img: PropTypes.element,
  reverseImg: PropTypes.bool,
  divider: PropTypes.element,
  bgBlueGreen: PropTypes.bool,
  titleSmall: PropTypes.bool,
  children: PropTypes.node,
};

Hero.HeroImage = HeroImage;

export default Hero;
