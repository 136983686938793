import React from "react";

import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import Divider from "components/Divider";
import ImgShadow from "components/ImgShadow";
import Section from "components/QantevSection";

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  color: #00033f;

  > *:not(:last-child) {
    margin-top: 0;
    margin-bottom: 25px;
  }

  p {
    color: #00033f;
    font-size: 15px;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    max-height: ${(props) => (props.large ? "350px" : "none")};
  }
`;

const Card = ({
  title,
  dividerIcon,
  dividerText,
  imgSrc,
  imgAlt,
  imgShadow,
  imgShadowReverse,
  imgLarge,
  alignImgLeft,
  learnMoreTo,
  children,
}) => {
  const { t } = useTranslation();

  const imgSection = (
    <Section.Right>
      <ImgWrapper>
        {(!imgShadow && <img src={imgSrc} alt={imgAlt} />) || (
          <ImgShadow
            src={imgSrc}
            alt={imgAlt}
            reverse={imgShadowReverse}
            large={imgLarge}
          />
        )}
      </ImgWrapper>
    </Section.Right>
  );

  return (
    <Section style={{ paddingTop: "10em", paddingBottom: "10em" }}>
      {alignImgLeft && imgSection}
      <Section.Left>
        <TextWrapper>
          <Divider
            icon={
              dividerIcon || (
                <div
                  style={{
                    textTransform: "uppercase",
                    color: "#00033f",
                    fontSize: "15px",
                    lineHeight: "24px",
                    fontWeight: 700,
                    letterSpacing: "1px",
                  }}
                >
                  {dividerText}
                </div>
              )
            }
            color="#00033f"
          />
          <h2 style={{ color: "#00033f" }}>{title}</h2>
          {children && (
            <p
              style={{
                color: "#00033f",
                fontSize: "16px",
                lineHeight: "23px",
              }}
            >
              {children}
            </p>
          )}
          {!!learnMoreTo && (
            <Link
              style={{
                textTransform: "uppercase",
                textDecoration: "underline",
                color: "#00033f",
              }}
              to={learnMoreTo}
            >
              {t("Learn more")} &gt;
            </Link>
          )}
        </TextWrapper>
      </Section.Left>
      {alignImgLeft || imgSection}
    </Section>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  dividerIcon: PropTypes.element,
  dividerText: PropTypes.string,
  imgSrc: PropTypes.element,
  imgAlt: PropTypes.string,
  imgShadow: PropTypes.bool,
  imgShadowReverse: PropTypes.bool,
  imgLarge: PropTypes.bool,
  alignImgLeft: PropTypes.bool,
  learnMoreTo: PropTypes.string,
  children: PropTypes.node,
};

Card.defaultProps = {
  dividerIcon: null,
  dividerText: null,
  imgSrc: null,
  imgAlt: null,
  imgShadow: false,
  imgShadowReverse: false,
  imgLarge: false,
  alignImgLeft: false,
  learnMoreTo: null,
  children: null,
};

export default Card;
