import styled from "styled-components";

const Shadow = styled.img`
  box-shadow: 0px 0px 10px 0px #959292, 10px -10px 0px 0px #00033f;
  border-radius: 30px;

  @media (min-width: 550px) {
    box-shadow: 0px 0px 10px 0px #959292,
      ${(props) => (props.reverse ? "-60px" : "60px")} -45px 1px 0px #00033f;
  }
`;

export default Shadow;
