import React from "react";

import { graphql } from "gatsby";

import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

import WeeClaimManagement from "assets/icons/wee-claims-management.svg";
import AlternativeProvider from "assets/images/alternative-provider.svg";
import ClaimsManagementFlow from "assets/images/claims-management-flow.svg";
import ClaimsManagement from "assets/images/claims-management.svg";
import ProviderQuoteTable from "assets/images/provider-quote-table.svg";
import ProviderRecommender from "assets/images/provider-recommender.png";
import ProviderServiceQuality from "assets/images/provider-service-quality.svg";
import QuoteAnalysis from "assets/images/quote-analysis.png";
import Card from "components/Card";
import Divider from "components/Divider";
import Hero from "components/Hero";
import HowWeDoIt from "components/HowWeDoIt";
import NetworkOptimizationServices from "components/NetworkOptimizationServices";
import Next from "components/Next";
import PageContainer from "components/PageContainer";
import SEO from "components/SEO";
import WhatWeOffer from "components/WhatWeOffer";

const ListWrapper = styled.ul`
  li {
    color: #00033f;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    margin-bottom: 18px;
    list-style-type: none;
  }

  li:last-child {
    margin-bottom: 0;
  }
`;

const ClaimsManagementPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Hero
        title="Claims Management"
        divider={
          <Divider
            icon={<img src={WeeClaimManagement} alt="wee-claims-management" />}
          />
        }
        img={
          <Hero.HeroImage
            src={ClaimsManagement}
            alt="folder-claims-details"
            loading="lazy"
          />
        }
      >
        {t(
          "Decision making tool for pre-authorisation that enables automated coverage and completeness checks, and advanced quote analysis thanks to medical coding coherence and pricing benchmarks."
        )}
      </Hero>
      <WhatWeOffer>
        <ListWrapper>
          <li>
            {t(
              "Automated extraction of relevant information and using trained AI to determine relevant coverage check details from underlying term/conditions and policies database"
            )}
          </li>
          <li>
            {t(
              "Automated decision based on matching the provided claims information and the trained conditions"
            )}
          </li>
          <li>
            {t(
              "Quote analysis using benchmarking & pricing analysis and medical coding coherence checks"
            )}
          </li>
          <li>
            {t(
              "Qantev patient journey analysis to check if service is necessary & suitable"
            )}
          </li>
          <li>{t("Alternative provider recommendation for SMO")}</li>
        </ListWrapper>
      </WhatWeOffer>
      <Card
        title={t("Pre-authorisation analysis report")}
        dividerText={t("QUOTE ANALYSIS")}
        imgSrc={QuoteAnalysis}
        imgAlt="quote-analysis"
        imgShadow
        imgShadowReverse
        alignImgLeft
      >
        {t(
          "Qantev provides comprehensive quote analysis using pricing benchmark and medical coding coherence checks. Claims manager also gets insights about patient journey."
        )}
      </Card>
      <Card
        title={t("Alternative provider recommendation")}
        dividerText={t("PROVIDER RECOMMENDER")}
        imgSrc={ProviderRecommender}
        imgAlt="provider-recommender"
        imgShadow
      >
        {t(
          "Using historical claims, patient profile and multiple data points, Qantev can help identify the best alternative provider for referrals or second medical opinions."
        )}
      </Card>
      <NetworkOptimizationServices>
        <NetworkOptimizationServices.Item
          caption={
            <Trans t={t} i18nKey="NORMALISED QUOTE TABLE">
              NORMALISED QUOTE
              <br />
              TABLE
            </Trans>
          }
          imgSrc={ProviderQuoteTable}
          imgAlt="provider-quote-table"
        />
        <NetworkOptimizationServices.Item
          caption={
            <Trans t={t} i18nKey="PROVIDER SERVICE QUALITY">
              PROVIDER
              <br />
              SERVICE QUALITY
            </Trans>
          }
          imgSrc={ProviderServiceQuality}
          imgAlt="provider-service-quality"
        />
        <NetworkOptimizationServices.Item
          caption={
            <Trans t={t} i18nKey="ALTERNATIVE PROVIDER RECOMMENDATION">
              ALTERNATIVE
              <br />
              PROVIDER RECOMMENDATION
            </Trans>
          }
          imgSrc={AlternativeProvider}
          imgAlt="alternative-provider"
        />
      </NetworkOptimizationServices>
      <HowWeDoIt
        description={t(
          "Qantev’s multiple highly specialised technological assets are enabling straight through processing capabilities thanks to automated coverage and completeness checks, while improving customer experience with quicker communication."
        )}
      >
        <img
          style={{ marginLeft: 64, marginRight: 64 }}
          src={ClaimsManagementFlow}
          alt="claims-management-flow"
        />
      </HowWeDoIt>
      <Next
        text={
          <>
            Fraud,
            <br />
            Waste & Abuse
          </>
        }
        to="/product/fraud-waste-and-abuse"
      />
    </PageContainer>
  );
};

export default ClaimsManagementPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="Claims Management" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["claimsManagement", "common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
