import React from "react";

import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import Section_ from "../QantevSection";

const Section = styled(Section_)`
  gap: 3em;

  @media (min-width: 500px) {
    gap: 10em;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: unset;
  padding: 0;

  p {
    color: ${(props) => (props.dark ? "#FFF" : "#00033F")};
    font-size: 18px;
    line-height: 1.7;
    font-weight: 300;
  }

  @media (min-width: 650px) {
    padding: 0;
  }

  @media (min-width: 850px) {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1em;

    h1 {
      flex: 1;
    }
    p {
      flex: 1;
    }
  }

  @media (min-width: 650px) {
    padding: 0 5vw;
  }
`;

const HowWeDoIt = ({ dark, description, children }) => {
  const { t } = useTranslation();

  return (
    <Section
      large
      dark={dark}
      style={{
        gridTemplateColumns: "1fr",
      }}
    >
      <Section.Left>
        <Wrapper dark={dark}>
          <h1 className="big" style={{ textDecoration: "capitalize" }}>
            <Trans t={t} i18nKey="How We Do It">
              How
              <br />
              We Do It
            </Trans>
          </h1>
          <p>{description}</p>
        </Wrapper>
      </Section.Left>
      {children && <Section.Right>{children}</Section.Right>}
    </Section>
  );
};

HowWeDoIt.defaultProps = {
  dark: false,
  description: null,
  children: null,
};
HowWeDoIt.propTypes = {
  dark: PropTypes.bool,
  description: PropTypes.string,
  children: PropTypes.node,
};

export default HowWeDoIt;
