import React from "react";

import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import Arrow from "assets/icons/arrow.svg";

import Divider from "../Divider";
import Section from "../QantevSection";

const ArrowNextWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: end;
  align-items: center;

  img:nth-child(2) {
    opacity: 0.5;
  }
  img:last-child {
    opacity: 0.15;
  }

  @media (min-width: 550px) {
    img {
      width: 40%;
    }
  }

  @media (min-width: 750px) {
    img {
      width: 8vw;
    }
  }
`;

const ArrowNext = ({ style }) => (
  <ArrowNextWrapper style={style}>
    <img src={Arrow} alt="arrow" />
    <img src={Arrow} alt="arrow" />
    <img src={Arrow} alt="arrow" />
  </ArrowNextWrapper>
);
ArrowNext.defaultProps = { style: {} };
ArrowNext.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const TextAndArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h1.big {
    font-size: 48px;
  }

  @media (min-width: 550px) {
    flex-direction: row;
    align-items: center;

    h1.big {
      flex: 2;
    }

    ${ArrowNextWrapper} {
      flex: 1;
    }
  }

  @media (min-width: 700px) {
    h1.big {
      font-size: 68px;
      line-height: 58px;
    }
  }
`;

const Next = ({ text, to }) => {
  const { t } = useTranslation();

  return (
    <Section
      large
      style={{
        gridTemplateColumns: "1fr",
        gap: "3em",
      }}
    >
      <Section.Left style={{ width: "calc(100vw / 2)" }}>
        <Divider
          icon={
            <div
              style={{
                textTransform: "uppercase",
                color: "#00033f",
                fontSize: "15px",
                lineHeight: "24px",
                fontWeight: 700,
                letterSpacing: "1px",
              }}
            >
              {t("NEXT")}
            </div>
          }
          color="#00033f"
        />
      </Section.Left>
      <Section.Right>
        <Link to={to}>
          <TextAndArrowWrapper>
            <h1 className="big">&gt;&gt;{text}</h1>
            <ArrowNext />
          </TextAndArrowWrapper>
        </Link>
      </Section.Right>
    </Section>
  );
};

Next.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Next;
