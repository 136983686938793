import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const Item = ({ imgSrc, imgAlt, caption }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex",
      alignItems: "center",
      gap: "2em",
    }}
  >
    {imgSrc && <img src={imgSrc} alt={imgAlt} />}
    <h4
      style={{
        textTransform: "uppercase",
        color: "#fff",
        textAlign: "center",
      }}
    >
      {caption}
    </h4>
  </div>
);

Item.propTypes = {
  caption: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
};

const NetworkOptimizationServices = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8em calc(13vw);
  gap: 5em;

  background-color: #00033f;
  background-image: linear-gradient(135deg, #00033f, #1f2058);

  img {
    width: 100px;
  }

  @media (min-width: 560px) {
    flex-direction: row;
    align-items: center;

    img {
      width: 120px;
    }
  }

  @media (min-width: 1024px) {
    img {
      width: 150px;
    }
  }
`;

NetworkOptimizationServices.Item = Item;

export default NetworkOptimizationServices;
