import React from "react";

import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 5em calc(13vw);
  background-image: linear-gradient(180deg, #e5e5e5, #fff);
  color: #00033f;

  @media (min-width: 950px) {
    padding-top: 6em;
    padding-bottom: 6em;
  }
`;

const FirstRowWrapper = styled.div`
  display: grid;

  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 68px;
    line-height: 70px;
    font-weight: 600;
    text-decoration: none;
    text-transform: capitalize;
  }
  p {
    color: #00033f;
    font-size: 20px;
    line-height: 1.7;
    font-weight: 300;
  }

  @media (min-width: 950px) {
    h2 {
      min-width: 300px;
    }
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
  }
`;

const WhatWeOffer = ({ offerText, children, footer }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <FirstRowWrapper>
        <h2>
          <Trans t={t} i18nKey="What we offer">
            What
            <br />
            we offer
          </Trans>
        </h2>
        {!!offerText && <p>{offerText}</p>}
        {children}
      </FirstRowWrapper>
      {footer}
    </Wrapper>
  );
};

WhatWeOffer.propTypes = {
  offerText: PropTypes.string.isRequired,
  children: PropTypes.node,
  footer: PropTypes.element,
};

WhatWeOffer.defaultProps = {
  children: null,
  footer: null,
};

export default WhatWeOffer;
