import styled, { css } from "styled-components";

import BGDotTexture from "assets/images/bg-dot-texture.svg";
import MaskDotTexture from "assets/images/mask-dot-texture.svg";

export const HeroTitle = styled.h1`
  font-size: 24px;
  line-height: 34px;

  @media (min-width: 320px) {
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 0.7em;
  }

  @media (min-width: 740px) {
    font-size: 58px;
    margin-bottom: 0;
    line-height: 70px;
  }

  @media (min-width: 950px) {
    font-size: 35px;
  }

  @media (min-width: 1024px) {
    font-size: ${(props) => (props.small ? "38px" : "68px")};
    line-height: 70px;
  }
`;
export const HeroSubtitle = styled.span`
  color: #fff;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  margin: 0;

  @media (min-width: 320px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (min-width: 740px) {
    font-size: 38px;
    line-height: 46px;
  }

  @media (min-width: 950px) {
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    font-size: 26px;
  }

  a {
    color: #12ce9f;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
`;

const blueBackground = css`
  background-image: url(${BGDotTexture}),
    linear-gradient(72deg, #1f2058 65%, #00033f);
  background-position: 0px 0px, 0px 0px;
  background-size: auto, auto;
`;

const blueGreenBackground = css`
  background-image: url(${MaskDotTexture}),
    linear-gradient(315deg, #128b9f, #1f2058 35%, #00033f 81%),
    radial-gradient(circle farthest-corner at 100% 100%, #000, #fff);
  background-position: 0px 0px, 0px 0px, 0px 0px;
  background-size: cover, auto, auto;
`;

export const HeroSection = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  ${(props) => (props.bgBlueGreen ? blueGreenBackground : blueBackground)}

  padding: 8em 1.5em 1em;

  @media (min-width: 400px) {
    padding-top: 6em;
  }

  @media (min-width: 1024px) {
    padding-top: 3em;
  }
`;

export const HeroImage = styled.img`
  max-width: 90%;
`;

export const HeroLeft = styled.div`
  & > div:last-child,
  & > div:last-child p {
    font-size: ${(props) => (props.small ? "18px" : "16px")};
    line-height: 27px;
    font-weight: 300;
    margin-bottom: 1.75em;
    padding: 0 2em 0 0;
  }

  @media (min-width: 740px) {
    display: flex;
    flex-direction: column;
    /* gap: 2em; */
    justify-content: start;
    height: 100%;
    padding-top: 1.5em;

    > *:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  @media (min-width: 1420px) {
    padding-top: 3em;
  }
`;

export const HeroContent = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 85vw;
  gap: 3em;

  grid-template-columns: 1fr;

  ${HeroImage} {
    margin: 0 auto;
    max-width: 70%;
  }

  @media (min-width: 320px) {
    ${HeroImage} {
      max-width: 90%;
    }
  }

  @media (min-width: 400px) {
    ${HeroImage} {
      max-width: 75%;
    }
  }

  @media (min-width: 576px) {
    ${HeroImage} {
      max-width: 50%;
    }
  }

  @media (min-width: 1024px) {
    grid-template-rows: unset;
    grid-template-columns: 1fr 1fr;

    ${HeroImage} {
      max-width: 80%;
    }

    ${HeroImage}.large {
      max-width: 95%;
    }

    ${HeroLeft} {
      grid-column-start: 1;
      grid-row-start: 1;
    }
  }
`;
